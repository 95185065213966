<template>
  <div class="buttons">
    <button v-if="!isRunning && !completed" class="button is-primary" @click.prevent="startHandle">
      <span class="icon">
        <unicon name="play" fill="#fff" />
      </span>
    </button>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useUser } from "@/use/useUser";
import useTimeTracking from '@/use/useTimeTracking'

export default {
   props:{
    issue: {
      type: Object,
    }
  },

  setup (props) {
    const {
      pause,
      isRunning,
      completed,
      // initData,
      initIssueData
    } = useTimeTracking();

    const { currentUser } = useUser()
    const state = reactive({
      count: 0,
    })

    const startHandle = () => {
      initIssueData(props.issue,currentUser.value.gitlab_id)
    }

    return {
      ...toRefs(state),
      pause,
      isRunning,
      completed,
      startHandle
    }
  }
}
</script>

<style lang="scss" scoped>

</style>